import { Component, OnInit } from '@angular/core';
import { CopyOperationService } from './copyOperations.service';
import { sequenceTableCol, copySequenceCol } from '../sequence/sequence-list';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-copy-operations',
  templateUrl: './copy-operations.component.html',
  styleUrls: ['./copy-operations.component.sass']
})
export class CopyOperationsComponent implements OnInit {
  partsList: Array<any>;
  cols: Array<any>;
  selectedOperationsLists: Array<any> = [];
  sequenceList: Array<any> = [];
  styleId: number;
  factoryId: number;
  styleName: string;
  searchStyleList: Array<any>;
  allStyle: any;
  selectedPartList: Array<any> = [];
  styleSequence: Array<any> = [];
  copiedSeqCol: any;
  copyStyleId: any;
  searchCopyStyleList: any[];
  allCopyStyle: any;
  spinner = false;
  copyStyleName: string;
  stageList: any;
  stageId: number;
  toStageId: number;
  factoryList: Array<any> = [];

  constructor(
    private service: CopyOperationService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.getFactoryId();
    this.getStages();
    const style = JSON.parse(localStorage.getItem('selectedStyle'));
    this.stageId = JSON.parse(localStorage.getItem('stageId'));
    this.styleName = style.name;
    this.styleId = style.id;
    this.cols = sequenceTableCol;
    this.copiedSeqCol = copySequenceCol;
    // this.getSequence(this.styleId,this.stageId);
  }

  /**
   * get Stages
   */
  getStages() {
    this.spinner = true;
    this.service.getStages().subscribe((response: any) => {
      this.stageList = response.data;
      this.stageId = this.stageList[0].id;
      this.toStageId = this.stageList[0].id;
      this.getSubsection();
      this.spinner = false;
    });
  }

  /**
   * get subsections related to selected style
   */
  getSubsection() {
    this.spinner = true;
    this.sequenceList = [];
    this.selectedPartList = [];
    this.partsList = [];
    this.service.getCopysSequence(this.styleId, this.stageId).subscribe((response: any) => {
      this.partsList = response.data;
      this.spinner = false;
      // this.getSequence(this.styleId, this.stageId);
    },
    );
  }

  /**
   * get Factory Id
   */
  getFactoryId() {
    this.spinner = true;
    this.service.getFactoryId().subscribe((response: any) => {
      this.factoryList = response.data;
      this.factoryId = this.factoryList[0].id;
      this.spinner = false;
    });
  }

  /**
   * to Search for available lists of style
   * @param event query to be searched
   */
  searchStyle(event) {
    this.styleId = null;
    this.service.searchStyles(event.query, this.factoryId).subscribe((response: any) => {
      const styleList = [];
      for (const style of response.data) {
        style.name = style.name;
        styleList.push(style.name);
      }
      this.searchStyleList = styleList.length > 0 ? styleList : ['No Styles Found'];
      this.allStyle = response.data;
    });
  }

  /**
   * get id of selected style
   * @param event selected style by user
   */
  captureStyle(event: any) {
    for (const list of this.allStyle) {
      if (list.name === event) {
        this.styleId = list.id;
        localStorage.setItem('selectedStyle', JSON.stringify(list));
      }
    }
    this.getSubsection();
    
  }

  /**
   * to Search for available lists of style
   * @param event query to be searched
   */
  searchCopyStyle(event) {
    this.copyStyleId = null;
    this.service.searchStyles(event.query, this.factoryId).subscribe((response: any) => {
      const copyStyleList = [];
      for (const style of response.data) {
        style.name = style.name;
        copyStyleList.push(style.name);
      }
      this.searchCopyStyleList = copyStyleList.length > 0 ? copyStyleList : ['No Styles Found'];
      this.allCopyStyle = response.data;
    });
  }

  /**
   * get id of selected style
   * @param event selected style by user
   */
  captureCopyStyle(event: any) {
    for (const list of this.allCopyStyle) {
      if (list.name === event) {
        this.copyStyleId = list.id;
      }
    }
    this.getCopySequence(this.copyStyleId, this.toStageId);
  }

  /**
   * callback function after copy stage change 
   */
  copyStage() {
    this.getCopySequence(this.copyStyleId, this.toStageId);
  }

  /**
   * Select or un select all employees
   * @param event (Select All Parts check box event)
   */
  toggleSelectAllParts(event): void {
    if (event.target.checked === true) {
      this.selectedPartList = this.partsList.map((obj) => {
        return obj.id;
      });
    
      // this.sequenceList = [...this.styleSequence];
      this.getSequence(this.styleId, this.stageId);
    } else {
      this.selectedPartList = [];
      this.sequenceList = [];
    }
  }

  /**
   * get sequences of selected part
   * @param partId selected Parts
   */

  // getSequenceList(ev, partId) {
  //   if (ev) {
  //     this.service.getSequenceList(this.styleId, partId, this.stageId).subscribe((response: any) => {
  //       const seqList = response.data ? response.data : [];
  //       this.sequenceList = [...this.sequenceList, ...seqList];
  //     },
  //     );
  //     this.selectedPartList.push(partId);
  //     // console.log(this.selectedPartList)
  //   } else {
  //     const clonedSequence = [...this.sequenceList];
     
  //    console.log(this.sequenceList)
  //     const index: number = this.selectedPartList.indexOf(partId);
  //     if (index !==-1) {
  //       this.selectedPartList.splice(index, 1);
  //     }
  //      for (const iterator of clonedSequence) {
  //       if (iterator.operation.partsDTO.id === partId) {
  //       console.log(partId, "partId")

  //         this.sequenceList.splice(iterator.operation.partsDTO.id, 1);
  //         console.log(this.sequenceList)
  //       }
  //     }
  //   }
  // }


  getSequenceList(ev: boolean, partId: number) {
  if (ev) {
    // Add sequence when checkbox is checked
    this.service.getSequenceList(this.styleId, partId, this.stageId).subscribe((response: any) => {
      const seqList = response.data ? response.data : [];
      this.sequenceList = [...seqList,...this.sequenceList];
    });
    this.selectedPartList.push(partId);
  } else {
    // Remove sequence when checkbox is unchecked
    this.sequenceList = this.sequenceList.filter(
      (item) => item.operation.partsDTO.id !== partId
    );

    // Remove partId from selected list
    this.selectedPartList = this.selectedPartList.filter((id) => id !== partId);
  }
}


  /**
   * get List of selected style id
   */
  getSequence(styleId, stageId) {
    this.service.getSequence(styleId, stageId).subscribe((response: any) => {
      if (this.selectedPartList.length === this.partsList.length) {
        this.sequenceList = [...this.sequenceList, ...response.data];
      }
    //   if (this.copyStyleId) {
    //     console.log(this.copyStyleId);
        
    //     this.styleSequence = [...response.data];
    //   }
    });
  }
  getCopySequence(styleId, stageId) {
    this.service.getSequence(styleId, stageId).subscribe((response: any) => {
    //   if (this.selectedPartList.length === this.partsList.length) {
    //     this.sequenceList = [...this.sequenceList, ...response.data];
    //   }
      if (this.copyStyleId) {
        console.log(this.copyStyleId);
        
        this.styleSequence = [...response.data];
      }
    });
  }

  /**
   * function to copy operation from one to another style
   */
  oncopyStylesOperations() {
    this.spinner = true;
    const selectedSequence = [];
    for (const iterator of this.selectedOperationsLists) {
      selectedSequence.push(iterator.id);
    }
    this.service.copySequenceList(this.copyStyleId, selectedSequence, this.toStageId).subscribe((response: any) => {
      this.getCopySequence(this.copyStyleId, this.toStageId);
      this.spinner = false;
      this.toastr.success(response.message, 'Success');
    },
      (error) => {
        this.spinner = false;
        this.toastr.error(error.error.message, 'Error');
      });
  }
}
